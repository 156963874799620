<template>
  <en-drawer title="其他费用明细" :model-value="modelValue" @close="$emit('update:model-value', false)" :size="1200">
    <en-table :data="form.data.otherCosts" :loading="form.loading">
      <en-table-column label="操作" width="80">
        <template #default="{ row, $index }: { row: EnocloudServiceDefinitions['ServiceOtherCostDto']; $index: number }">
          <en-button type="primary" link @click="form.otherCosts.discard.click($index)">删除</en-button>
        </template>
      </en-table-column>
      <en-table-column label="名称">
        <template #default="{ row }: { row: EnocloudServiceDefinitions['ServiceOtherCostDto'] }">
          <en-input v-model="row.name"></en-input>
        </template>
      </en-table-column>
      <en-table-column label="数量">
        <template #default="{ row }: { row: EnocloudServiceDefinitions['ServiceOtherCostDto'] }">
          <en-input-number v-model="row.count" :min="0" class="w-full"></en-input-number>
        </template>
      </en-table-column>
      <en-table-column label="单价">
        <template #default="{ row }: { row: EnocloudServiceDefinitions['ServiceOtherCostDto'] }">
          <en-input-number v-model="row.price" :min="0" :precision="2" class="w-full"></en-input-number>
        </template>
      </en-table-column>
      <en-table-column label="金额">
        <template #default="{ row }: { row: EnocloudServiceDefinitions['ServiceOtherCostDto'] }">{{ calculator.mul(row.count, row.price) }}</template>
      </en-table-column>
      <en-table-column label="成本">
        <template #default="{ row }: { row: EnocloudServiceDefinitions['ServiceOtherCostDto'] }">
          <en-input-number v-model="row.cost" :min="0" :precision="2" class="w-full"></en-input-number>
        </template>
      </en-table-column>
      <en-table-column label="备注">
        <template #default="{ row }: { row: EnocloudServiceDefinitions['ServiceOtherCostDto'] }">
          <en-input v-model="row.comment"></en-input>
        </template>
      </en-table-column>
    </en-table>

    <template #footer>
      <en-button @click="footer.add.click">新增</en-button>
      <en-button @click="footer.cancel.click">取消</en-button>
      <button-ajax :method="footer.confirm.click">确定</button-ajax>
    </template>
  </en-drawer>
</template>

<script lang="ts">
import { calculator } from '@enocloud/utils'

export default factory({
  props: {
    modelValue: Boolean,
    data: Object as PropType<EnocloudServiceDefinitions['ServiceDto']>
  },

  emits: {
    'update:model-value': (value: boolean) => typeof value === 'boolean',
    confirm: () => true
  },

  watch: {
    modelValue: {
      handler(visible) {
        if (visible) {
          this.form.get()
        } else {
          this.form.init()
        }
      }
    }
  },

  config: {
    children: {
      form: {
        ajax: {
          get: {
            action: 'GET /enocloud/service/:serviceId',
            data: 'object',
            init: true,
            loading: true,
            params(params) {
              params.paths = [this.data?.id]
            }
          },
          submit: {
            action: 'PUT /enocloud/service',
            params(params) {
              params.payload = this.form.data
            }
          }
        },
        children: {
          otherCosts: {
            discard: {
              click(index: number) {
                this.form.data.otherCosts?.splice(index, 1)
              }
            }
          }
        }
      },
      footer: {
        children: {
          add: {
            click() {
              this.form.data.otherCosts?.unshift({ amount: 0, cost: 0, count: 1, price: 0, comment: '', name: '' })
            }
          },
          cancel: {
            click() {
              this.emit('update:model-value', false)
            }
          },
          confirm: {
            async click() {
              await this.form.submit()
              this.emit('update:model-value', false)
              this.emit('confirm')
            }
          }
        }
      }
    }
  }
})
</script>
